@import '../../../scheme/config.scss';

.nav-bar__toggle {
  width: 50px;
  height: 50px;
  display: none;
  transition: 0.4s all ease;
  z-index: 1;

  @media (max-width: $break-medium) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.nav-bar__toggle svg {
  fill: white;
  width: 38px;
  height: 38px;
}

.nav-bar__list {
  font-size: 18px;
  font-family: var(--font-header);
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-around;
  transition: .3s all ease; 

  @media (max-width: $break-medium) {
    width: 100vw;
    height: 100vh;
    background-color: var(--color-purple);
    padding: 30px 15px;
    margin: 0;
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -90%;
    transform: translateX(100vw);
  }
}

.nav-bar__list.list-expanded {
  @media (max-width: $break-medium) {
    right: 0;
    transform: translateX(0);
  }
}

.nav-bar__item {
  padding: 4px 30px;
  display: block;
  transition: .8s all ease-in-out;

  @media (max-width: $break-medium) {
    padding: 20px 30px;
    font-size: 38px;
    opacity: 0;
  }
}

.list-expanded .nav-bar__item {
  @media (max-width: $break-medium) {
    opacity: 1;
  }
}

.nav-bar__item.contact {
  @media (min-width: $break-medium) {
    display: none;
  }
}

a.nav-bar__link {
  color: white;
  text-decoration: none;
  padding-bottom: 4px;
  
  &.active {
    border-bottom: 1px solid var(--color-pink);
  }

  @media (max-width: $break-medium) {
    opacity: 0.7;

    &.active {
      opacity: 1;
    }
  }
}

.contact-button__mobile {
  @media (max-width: $break-medium) {
    position: fixed;
    width: 32px;
    height: 32px;
    top: 13px;
    right: 120px;
    z-index: 99;
    opacity: .8;

    & svg {
      fill: white;
    }
  }

  @media (min-width: $break-medium) {
    display: none;
  }
}

.list-expanded ~ .contact-button__mobile  {
  display: none;
}