@import '../../../scheme/config.scss';

.banner {
  padding-top: 20px;
  padding-bottom: 50px;
  background-color: white;

  @media (max-width: $break-medium) {
    padding-top: 0px;
    padding-bottom: 20px;
  }
}

.banner:nth-child(odd) {
  background: linear-gradient(to bottom, var(--color-grey-100) 0%,var(--color-grey-100) 60%,var(--color-grey-100) 60%,white 60%,white 100%); /* W3C */
}

.banner__title {
  color: var(--color-purple);
  margin-bottom: 28px;
  font-size: 38px;

  @media (max-width: $break-medium) {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
  }
}

.banner__list {
  display: flex;
  flex-wrap: wrap;
  min-height: 380px;

  @media (max-width: $break-medium) {
    display: block;
    height: unset;
    min-height: unset;
  }
}

.banner-item {
  color: white;
  text-decoration: none;
  min-width: 300px;
  height: auto;
  background-color: var(--color-purple-dark);
  padding-top: 200px;
  flex: 1;
  position: relative;
  display: flex;
  box-sizing: border-box;

  @media (max-width: $break-medium) {
    padding-top: 0;
    margin: 4px 0;
    overflow: hidden;
  }
}

.banner-item:before {
  content: "";
  background: transparent linear-gradient(0deg, #6934BF 0%, #872AC3 20%, #FF00D4 100%) 0% 0% no-repeat padding-box;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: .4s all ease;
}

.banner-item:hover:before {
  opacity: 1;
}

.banner-item:hover .banner-item__image {
  opacity: .3;
}

.banner-item__fg {
  position: relative;
  z-index: 1;
  padding: 0 20px;

  @media (max-width: $break-medium) {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px 10px;
  }
}

.banner-item__name {
  font-size: 24px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  
  @media (max-width: $break-medium) {
    font-size: 22px;
    margin-right: -10px;
    margin-bottom: 0;
  }
}

.banner-item__description {
  @media (max-width: $break-medium) {
    font-size: 16px;
    opacity: .7;
    line-height: 1.3;
    margin-right: -5px;
  }
}

.banner-item__icon {
  fill: white;
  height: 100%;
  opacity: 0;
  transition: .5s all;

  @media (max-width: $break-medium) {
    display: none;
  }
}

.banner-item:hover .banner-item__icon {
  margin-left: 1rem;
  opacity: 1;
}

// In page banner hover icon effect
.in-page .banner-item__icon {
  position: absolute;
  top: 0;
  right: 10px;
  transform: rotate(90deg);
}

.in-page .banner-item:hover .banner-item__icon {
  transform: rotate(90deg) translateX(60px);
}

.banner-item__image {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity: .55;
  transition: .4s all ease;

  @media (max-width: $break-medium) {
    width: 40%;
    left: unset;
    right: 0;
  }
}
