@import '../../../scheme/config.scss';

.banner--schedule {
  background: white;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.banner__list--schedule {
  width: 100%;
  border-collapse: collapse;
}

.schedule-row {
  text-align: left;
  justify-content: space-between;

  @media (max-width: $break-medium) {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 20px 0;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(0, 0, 0, .15);
  }

  @media (min-width: $break-medium) {
      &:nth-child(odd) {
        background-color: #F7F7F7;
      }
    
      &:last-child {
        border-bottom: 1px solid var(--color-pink);
      }
  }

}

.banner__list--schedule thead .schedule-row {
  font-size: 14px;
  opacity: .7;

  @media (max-width: $break-medium) {
    display: none;
  }
}

.banner__list--schedule th {
  padding: 6px 18px;
  opacity: .8;
}

.schedule-row__field {
  @media (max-width: $break-medium) {
    padding: 5px 10px;
  }

  @media (min-width: $break-medium) {
    padding: 20px 18px;
    border-top: 1px solid var(--color-pink);
  }
}

.schedule-row__field.regular {
  @media (max-width: $break-medium) {
    text-align: center;
  }
}

// first field in a row (title)
.schedule-row td:first-child {
  font-weight: 800;
  font-family: var(--font-body-secondary);
  width: auto;

  @media (max-width: $break-medium) {
    color: var(--color-pink);
    font-size: 16px;
    opacity: 0.7;
    text-align: center;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid var(--color-pink);
  }
}

// second field in a row (subtitle)
.schedule-row__field.address {
  font-style: italic;
  opacity: .5;
}

.schedule__link {
  background-color: var(--color-purple);
  color: white;
  text-decoration: none;
  padding: 8px 14px;
  border-radius: 2px;
  display: inline-block;

  &:hover {
    opacity: .7;
  }
}

.schedule__bottom-notes {
  font-style: italic;
  text-align: right;
  opacity: .5;
}