@import '../../../scheme/config.scss';

.language-switcher {
  color: white;
  font-size: 18px;
  font-family: var(--font-body-secondary);
  background-color: var(--color-grey-100);
  width: 4rem;
  height: 100%;
  border: none;
  display: inline-block;
  background: var(--color-purple);
  position: absolute;
  right: 0;
  top: 0;
  
  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    text-decoration: underline;
  }

  @media (max-width: $break-medium) {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 65px;
    margin: 9px;
    background: none;
    width: 40px;
    height: 40px;
    opacity: .8;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 30px;
      background-color: white;
      right: -5px;
      opacity: .2;
    }
  }
}
