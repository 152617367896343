@import './config.scss';

body {
  font-family: var(--font-body);
}

hr {
  opacity: .3;
  border: .5px solid white;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font-header);
  font-weight: 100;
  letter-spacing: .5px;
}

h1 {
  font-weight: 100;
}

select,
options,
input,
textarea {
  color: white;
  font-size: 18px;
  outline: none;
  background-color: transparent;
  height: 2rem;
  padding: 4px 2px;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0;
  box-shadow: 0;
}

select {
  // Reset defaul select
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  height: 2.5rem;
  background-color: var(--color-purple-dark);
  border-bottom: 1px solid white;
}

textarea {
  min-height: 100px;
}

label {
  color: white;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: var(--color-pink);
}

.title {
  font-size: 42px;
  line-height: 1;

  @media (max-width: $break-medium) {
    font-size: 30px;
  }
}

.subtitle {
  font-size: 16px;
  font-family: var(--font-body-secondary);
  margin-top: 0;
  letter-spacing: .5px;
  opacity: .7;
  line-height: 1;

  @media (max-width: $break-medium) {
    font-size: 14px;
    opacity: .8;
  }
}

p.small {
  font-size: .9rem;
  margin: 0;
  opacity: .8;
}

// Float field
.field--float ::placeholder {
  color: rgba(255,255,255,.5);
}

.field--float {
  text-transform: lowercase;
  margin: 4px 0;
}

.field--float label {
  opacity: 0;
  position: absolute;
  transition: .3s all ease;
}

.field--float:focus-within {
  & label {
    color: var(--color-pink);
    opacity: 1;
    display: inline;
    font-size: 14px;
    transform: translate(4px, -14px);
  }

  & ::placeholder {
    display: none;
    opacity: 0;
  }
}

.container {
  padding: 20px 40px;
  max-width: 1100px;
  margin: auto;

  @media (max-width: $break-medium) {
    padding: 20px 12px;
  }
}

.columns {
  display: flex;
  justify-content: space-between;

  @media (max-width: $break-medium) {
    flex-direction: column;
  }
}

.half-width {
  width: 50%;
  @media (max-width: $break-medium) {
    width: 100%;
  }
}

.field {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

@mixin staticButton {
  font-size: 14px;
  font-family: var(--font-body-secondary);
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 26px;
  margin: 8px 0;
  border: none;
  background: #fb00ca; /* Old browsers */
  background: -moz-linear-gradient(right, var(--color-pink) 0%, var(--color-purple) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(right, var(--color-pink) 0%,var(--color-purple) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to left, var(--color-pink) 0%,var(--color-purple) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb00ca', endColorstr='#7c00fe',GradientType=1 ); /* IE6-9 */
  letter-spacing: 1px;
  font-weight: 100px;
  display: inline-block;
  position: relative;
  transition: .4s all ease;
}

.button {
  @include staticButton;

  &:hover {
    cursor: pointer;
  }
}

.button:before {
  content: "";
  position: absolute;
  width: 0%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: .4s all ease-in-out;
  z-index: 0;
  opacity: 0;
  background: var(--color-purple);
}

.button:hover:before {
  width: 100%;
  opacity: 1;
  background: linear-gradient(to right, var(--color-pink) 100%,var(--color-purple) 40%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.button__text {
  position: relative;
  z-index: 1;
}

.button--disabled {
  @include staticButton;
}

.button--loading {
  @include staticButton;

  padding: 2px 18px;
  margin: auto;
  display: flex;
  align-items: center;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  transform: rotate(45deg);
  transform-origin: 32px 32px;
}
.lds-heart div {
  top: 23px;
  left: 19px;
  position: absolute;
  width: 26px;
  height: 26px;
  background: #fff;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  background: #fff;
}
.lds-heart div:before {
  left: -17px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -17px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes appear {
  0% {
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}

// Gradient background sample usage:
// background: linear-gradient(-45deg, var(--color-purple-dark), var(--color-black), #690067, var(--color-black));
// background-size: 400% 400%;
// animation: gradient 12s ease infinite;

// Gradient background
@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}
