@import '../../../scheme/config.scss';

:root {
  --subtitle-color: #cf96ff;
}

.page-content {
  padding-top: 60px;
  background: linear-gradient(-45deg,  var(--color-purple-darkest), var(--color-purple-dark), var(--color-black), var(--color-black));
  background-size: 400% 400%;
  animation: gradient 14s ease infinite;
}

.about .container {
  @media (max-width: $break-medium) {
    padding: 0;
  }
}

.about__banner {
  color: white;
  display: flex;
  margin-bottom: 20px;

  @media (min-width: $break-medium) {
    &:nth-child(even) {
      flex-direction: row-reverse;

      & .title {
        color: var(--color-purple);
      }
  
      & .subtitle {
        color: var(--subtitle-color);
        font-weight: bold;
      }
    }
  }

  @media (max-width: $break-medium) {
    flex-direction: column;
  }
}

.about__banner .container {
  display: flex;

  @media (max-width: $break-medium) {
    flex-direction: column;
  }
}

.banner__context {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $break-medium) {
    position: relative;
    top: 0;
    color: white;
    background-color: rgba(0,0,0, .8);
    padding: 0 20px 20px 20px;
  }
}

.banner__context .title {
  color: var(--color-pink);
  margin-bottom: 4px;
}

.banner__context p {
  opacity: .7;
  line-height: 1.6;

  @media (max-width: $break-medium) {
    line-height: 1.4;
    opacity: .6;
  }
}

.banner__context .subtitle {
  color: var(--color-pink);
  opacity: .7;
}

.banner__image-wrapper {
  flex: 1;
  top: 60px;
  position: sticky;
}

.about__banner__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.about__banner .button {
  margin-right: 10px;
}
