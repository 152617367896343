@import '../../../../scheme/config.scss';
@import '../../../../scheme/scheme.scss';

.hero {
  @include hero-gradient;
}

.hero .container {
  display: flex;
  height: 100vh;
  min-height: 680px;
  max-height: 800px;
  padding: 0 40px;

  @media (max-width: $break-medium) {
    display: block;
    height: 650px;
    width: 100vw;
    overflow: hidden;
    padding: 0;
    min-height: unset;
    max-height: unset;
    position: relative;
  }
}

.hero__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 3;

  @media (max-width: $break-medium) {
    height: 100%;
    position: unset;
    display: block;
    z-index: 2;
    background-color: rgba(0,0,0,0.1);
  }
}

.hero__left__content {
  width: 100%;
  max-width: 580px;
  color: white;

  @media (max-width: $break-medium) {
    width: unset;
    padding: 0 20px 20px 20px;
    margin: 0 10px 0 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

.hero__title {
  font-size: 48px;

  @media (max-width: $break-medium) {
    font-size: 90px;
    line-height: 1;
    margin: 0;
  }
}

.hero__subtitle {
  font-size: 18px;
  
  @media (max-width: $break-medium) {
    letter-spacing: .5px;
    opacity: .9;
    font-family: var(--font-body-secondary);
    font-weight: 300;
    line-height: 1.3;
    text-shadow: 0 0 2px black;
  }
}

.hero__description {
  @media (max-width: $break-medium) {
    display: none;
  }
}

.hero__button-wrapper {
  @media (max-width: $break-medium) {
    display: none;
  }
}

.hero__right {
  display: flex;
  align-items: flex-end;
  padding-top: 80px;
  flex: 2;

  @media (max-width: $break-medium) {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100vw;
    padding: 0;
    overflow-x: hidden;
  }
}

.hero__image {
  width: unset;
  min-height: 95%;
  object-fit: cover;

  @media (max-width: $break-medium) {
    height: 90%;
    min-height: unset;
    position: absolute;
    right: -110px;
    bottom: 0;
  }
}
