@import '../../../scheme/config.scss';

.header {
  @include gradient;
  width: 100%;
  padding: 0 20px;
  padding-right: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  box-sizing: border-box;
  transition: all 1s ease;

  @media (max-width: $break-medium) {
    height: 60px;
    justify-content: space-between;
  }
}

.header__text--mobile {
  display: none;
  color: white;
  text-decoration: none;

  @media (max-width: $break-medium) {
    display: block;
  }
}

.header__text--mobile h1 {
  font-size: 26px;
}

.header.off-top {
  box-shadow: 0 0 6px rgba(0,0,0,.2);
}
