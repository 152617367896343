@import '../../../scheme/config.scss';

.dance-page {
  text-align: center;
}

.contact__cta {
  background: white;
  padding: 20px;
  padding-bottom: 30px;

  & .button {
    margin: 5px 10px;
  }
}

.contact__cta--services {
  color: white;
  background-color: var(--color-purple-darkest);
}

.contact__cta .title {
  margin-bottom: 20px;
}

.contact__cta .subtitle {
  margin-bottom: 16px;
}

.contact__cta__container {
  max-width: 800px;
  padding: 0 10px;
  margin: auto;
}

.dance-page .gallery__slider {
  position: sticky;
  top: 58px;
  z-index: -1;
}

.class__schedule__button-wrapper {
  background-color: white;
  text-align: right;

  @media (max-width: $break-medium) {
    text-align: center;
  }
}
