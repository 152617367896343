@import '../../../scheme/config.scss';

.gallery {
  @include gradient;
  padding: 80px 0 0 0;
  width: 100%;
  color: white;
}

.gallery > .container {
  max-width: 800px;
}

.gallery__title {
  @media (max-width: $break-medium) {
    margin: 0;
    font-size: 28px;
  }
}

.gallery__slider {
  width: 100%;
  height: 300px;
  margin: 30px 0;
  text-align: center;
  margin: auto;
  margin-top: -2px;
  margin-bottom: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery__slider__content {
  height: 300px;
  width: auto;
  margin: auto;
  object-fit: cover;
}

.slick-arrow {
  display: none;
  // position: absolute;
  // top: 50%;
  // z-index: 2;

  // &:hover {
  //   cursor: pointer;
  //   opacity: .6;
  // }

  // &.slick-prev {
  //   left: 0;
  //   display: flex;
  //   justify-content: flex-end;

  //   &:before {
  //     content: '';
  //   }
  // }

  // &.slick-next {
  //   right: 0;

  //   &:before {
  //     content: '';
  //   }
  // }
}