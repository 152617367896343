:root {
  --color-pink: #FF00D4;
  --color-pink-light: lightpink;
  --color-purple: #910DFF;
  --color-purple-transparent: rgba(105, 52, 191, .6);
  --color-purple-dark: rgb(105, 52, 191);
  --color-purple-darkest: #200033;
  --color-grey-100: #eee;
  --color-grey-500: #555;
  --color-black: #000;
  --font-sans-serif: 'Open Sans', sans-serif;
  --font-sans-serif-secondary: 'Montserrat', 'Open Sans', sans-serif;
  --font-serif: 'Arapey', serif;

  --font-header: var(--font-serif);
  --font-body: var(--font-sans-serif);
  --font-body-secondary: var(--font-sans-serif-secondary);

  --breakpoint-xs: 350px;
  --breakpoint-sm: 800px;
}

$break-small: 500px;
$break-medium: 800px;

@mixin gradient {
  background: -webkit-gradient( linear, left top, right top, color-stop(0, rgba(215, 0, 242, 1)), color-stop(40%, rgba(122, 8, 237, 1)), color-stop(90%, rgba(54,21,105,1)));
  background: transparent -o-linear-gradient( left, rgba(215, 0, 242, .8) 0, rgba(122, 8, 237, .5) 40%, rgba(54,21,105,.6) 90%);
  background: linear-gradient( 90deg, rgba(215, 0, 242, 1) 0, rgba(122, 8, 237, 1) 40%, rgba(54,21,105,1) 90%);
}

@mixin hero-gradient {
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,d700f2+0,7a08ed+28,0a0606+95 */
  background: #1e5799; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  #1e5799 0%, #d700f2 0%, #7a08ed 28%, #0a0606 95%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  #1e5799 0%,#d700f2 0%,#7a08ed 28%,#0a0606 95%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  #1e5799 0%,#d700f2 0%,#7a08ed 28%,#0a0606 95%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#0a0606',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}