@import '../../../scheme/config.scss';

.footer {
  background-color: var(--color-purple-dark);
  color: white;
  padding-top: 30px;
  padding-bottom: 20px;
}

.footer h1 {
  color: white;
}

.footer h3 {
  font-size: 30px;
  margin-top: 6px;
  margin-bottom: 1rem;
}

.footer__cta-message {
  font-size: 32px;
  line-height: 1.15;
  margin-top: 0;
}

.footer .container {
  max-width: 640px;
}

.footer__contact__container {
  @media (min-width: $break-medium) {
    display: flex;
    justify-content: space-between;
  }
}

.footer__column {
  @media (max-width: $break-medium) {
    &:not(:first-child) {
      border-top: 1px solid rgba(255,255,255,0.3);
      margin-top: 15px;
      padding-top: 15px;
    }
  }
  
  @media (min-width: $break-medium) {
    width: 50%;
    padding-right: 10px;
  
    &:not(:first-child) {
      margin-left: 40px;
    }
  }
}

.footer__social-media__link,
.footer__phone-number {
  display: block;
  color: white;
  text-decoration: none;

  &:hover {
    opacity: .4;
  }
}

.footer__notation {
  text-align: center;
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1200px;

  & hr {
    margin-bottom: 20px;
  }

  & p {
    opacity: .8;
  }

  & a {
    color: white;

    &:hover {
      text-decoration: none;
    }
  }
}